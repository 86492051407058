'use client';

import ErrorView from '@nm-namshi-frontend/core/page_components/shared/error/ErrorView';

export default function Error({ error, reset }: { error: Error; reset: () => void }) {
    return (
        <div style={{ marginTop: '100px' }}>
            <ErrorView error={error} onAction={reset} />
        </div>
    );
}
