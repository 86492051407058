'use client';

import React from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { ERRORVIEW, ROUTES, SCREEN_SIZES } from '@nm-namshi-frontend/core/constants/uiConstants';
import Button from '@nm-namshi-frontend/core/components/Button';
import { IMG_BASE } from '@nm-namshi-frontend/core/config';
import useWindowDimensions from '@nm-namshi-frontend/core/hooks/useWindowDimensions';
import { ICONS } from '@nm-namshi-frontend/core/theme/icon';
import { captureException } from '@sentry/nextjs';

import styles from './ErrorView.module.scss';

type TProps = {
    actionStyle?: string;
    actionText?: string;
    className?: string;
    type?: ERRORVIEW;
    onAction?: () => void;
    error?: Error | string;
};
// @TODO Refactor this method to accomodate AR content if required
const getErrorInfo = (type: ERRORVIEW): { title: string; subTitle: string; icon: string } => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { t } = useTranslation('common');
    switch (type) {
        case ERRORVIEW.EMPTY_ADDRESSES:
            return {
                title: `${t('errors.empty-addresses-title')}`,
                subTitle: `${t('errors.empty-addresses-subtitle')}`,
                icon: `${IMG_BASE}/sivvi/design-system/empty-states/empty-addresses.svg`,
            };

        case ERRORVIEW.EMPTY_BAG_IN_CHECKOUT:
            return {
                title: `${t('errors.empty-bag-in-checkout-title')}`,
                subTitle: `${t('errors.empty-bag-subtitle')}`,
                icon: `${IMG_BASE}/sivvi/design-system/empty-states/empty-bag.svg`,
            };

        case ERRORVIEW.EMPTY_WISHLIST:
            return {
                title: `${t('errors.empty-wishlist-title')}`,
                subTitle: `${t('errors.empty-wishlist-subtitle')}`,
                icon: ICONS.heart,
            };
        case ERRORVIEW.EMPTY_WARDROBE:
            return {
                title: `${t('errors.empty-wardrobe-title')}`,
                subTitle: `${t('errors.empty-wardrobe-subtitle')}`,
                icon: ICONS.heart,
            };
        case ERRORVIEW.EMPTY_CREDITS:
            return {
                title: `${t('errors.empty-credits-title')}`,
                subTitle: `${t('errors.empty-credits-subtitle')}`,
                icon: `${IMG_BASE}/sivvi/design-system/empty-states/empty-credits.svg`,
            };
        case ERRORVIEW.EMPTY_EXCHANGES:
            return {
                title: `${t('errors.empty-exchanges-title')}`,
                subTitle: `${t('errors.empty-exchanges-or-returns-subtitle')}`,
                icon: `${IMG_BASE}/sivvi/design-system/empty-states/empty-exchanges.svg`,
            };
        case ERRORVIEW.EMPTY_RETURNS:
            return {
                title: `${t('errors.empty-returns-title')}`,
                subTitle: `${t('errors.empty-exchanges-or-returns-subtitle')}`,
                icon: `${IMG_BASE}/sivvi/design-system/empty-states/empty-return-exchanges.svg`,
            };
        case ERRORVIEW.EMPTY_ELIGIBLE_ITEMS_FOR_EXCHANGE:
            return {
                title: `${t('errors.empty-eligible-items-for-exchanges-title')}`,
                subTitle: `${t('errors.empty-orders-subtitle')}`,
                icon: `${IMG_BASE}/sivvi/design-system/empty-states/empty-orders.svg`,
            };
        case ERRORVIEW.EMPTY_ELIGIBLE_ITEMS_FOR_RETURN:
            return {
                title: `${t('errors.empty-eligible-items-for-returns-title')}`,
                subTitle: `${t('errors.empty-orders-subtitle')}`,
                icon: `${IMG_BASE}/sivvi/design-system/empty-states/empty-orders.svg`,
            };
        case ERRORVIEW.EMPTY_ORDERS:
            return {
                title: `${t('errors.empty-orders-title')}`,
                subTitle: `${t('errors.empty-orders-subtitle')}`,
                icon: ICONS.noOrder,
            };

        case ERRORVIEW.EMPTY_SAVED_CARDS:
            return {
                title: `${t('errors.empty-saved-cards-title')}`,
                subTitle: '',
                icon: ICONS.savedCards,
            };
        case ERRORVIEW.EMPTY_USER_COUPONS:
            return {
                title: `${t('errors.empty-user-coupons-title')}`,
                subTitle: `${t('errors.empty-user-coupons-description')}`,
                icon: `${IMG_BASE}/namshi/icons/no-coupons.svg`,
            };
        case ERRORVIEW.ORDER_ERROR:
            return {
                title: `${t('errors.order-error-title')}`,
                subTitle: `${t('errors.order-error-subtitle')}`,
                icon: `${IMG_BASE}/sivvi/design-system/empty-states/error-order.svg`,
            };
        case ERRORVIEW.OTP_TRIGGER_FAILURE:
            return {
                title: `${t('errors.otp-trigger-failure-title')}`,
                subTitle: `${t('errors.otp-trigger-failure-subtitle')}`,
                icon: `${IMG_BASE}/sivvi/design-system/empty-states/error-otp.svg`,
            };
        case ERRORVIEW.CATALOG_NOT_FOUND:
            return {
                title: `${t('errors.catalog-not-found-title')}`,
                subTitle: `${t('errors.catalog-not-found-subtitle')}`,
                icon: `${IMG_BASE}/sivvi/design-system/empty-states/empty-catalog.svg`,
            };
        case ERRORVIEW.NO_SEARCH_RESULT:
            return {
                title: `${t('errors.catalog-not-found-title')}`,
                subTitle: `${t('errors.catalog-not-found-subtitle')}`,
                icon: ICONS.search,
            };
        case ERRORVIEW.NO_FOLLOWED_BRANDS:
            return {
                title: `${t('errors.no-followed-brands')}`,
                subTitle: `${t('errors.follow-brands')}`,
                icon: ICONS.heart,
            };
        case ERRORVIEW.NO_PREVIOUS_REVIEWS:
            return {
                title: `${t('errors.no-previous-reviews')}`,
                subTitle: `${t('errors.no-previous-reviews-subtitle')}`,
                icon: `${IMG_BASE}/sivvi/design-system/empty-states/empty-past-reviews.svg`,
            };
        case ERRORVIEW.NO_PRODUCTS_TO_REVIEW:
            return {
                title: `${t('errors.no-products-to-review')}`,
                subTitle: `${t('errors.no-products-to-review-subtitle')}`,
                icon: `${IMG_BASE}/sivvi/design-system/empty-states/empty-products-to-review.svg`,
            };
        case ERRORVIEW.GENERIC_ERROR:
        default:
            return {
                title: `${t('errors.generic-error-title')}`,
                subTitle: `${t('errors.generic-error-subtitle')}`,
                icon: `${IMG_BASE}/sivvi/design-system/empty-states/error-general.svg`,
            };
    }
};

export default function ErrorView({
    actionStyle,
    actionText,
    className,
    error,
    onAction,
    type = ERRORVIEW.GENERIC_ERROR,
}: TProps) {
    const { icon, subTitle, title } = getErrorInfo(type);
    const { width: viewportWidth } = useWindowDimensions();
    const { t } = useTranslation();

    const isMobile = viewportWidth <= SCREEN_SIZES.screenMinSm;

    React.useEffect(() => {
        if (error) {
            console.error('CLIENT ERROR: ', error);
            captureException(error);
        }
    }, []);

    // Renders
    const getImages = () => {
        switch (type) {
            case ERRORVIEW.EMPTY_WISHLIST:
            case ERRORVIEW.NO_SEARCH_RESULT:
            case ERRORVIEW.EMPTY_USER_COUPONS:
            case ERRORVIEW.NO_FOLLOWED_BRANDS:
                return <img src={icon} alt={title} width={isMobile ? 30 : 50} height={isMobile ? 30 : 50} />;
            default:
                return <img src={icon} alt={title} width={180} height={180} />;
        }
    };

    return (
        <div className={clsx(styles.container, className)}>
            <div className={styles.content}>
                <div className={styles.imageContainer}>
                    <div className={clsx(styles.roundedIcon)}>{getImages()}</div>
                </div>
                <h2 className={styles.title}>{title}</h2>
                {subTitle && <p className={styles.subTitle}>{subTitle}</p>}

                <div className={styles.actionView}>
                    {error && (
                        <a href={ROUTES.HOME}>
                            <Button variant="secondary">{t('home')}</Button>
                        </a>
                    )}
                    {onAction && (
                        <Button onClick={() => onAction()} customStyle={actionStyle}>
                            {actionText || 'Try Again'}
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
}
